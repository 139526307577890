.MuiDialog-paperFullWidth {
  border-radius: 22px;
}

.wrapper {
  width: 38rem;
  padding: 2rem;
  max-height: 75vh;
  overflow: auto;
  height: auto;
}

.access_text {
  font-size: 0.8rem;
  font-family: 'UrbanistSemiBold';
  color: #14202a;
}

.button_width {
  width: 35%;
  margin-left: 0.5rem;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #7389c2;
  opacity: 0.2;
  border-radius: 33px;
  margin: 1rem 0;
}

.checkbox_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2rem;
  margin: 1rem 0;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sp_b {
  justify-content: space-between;
}

.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.mr-2 {
  margin-right: 2rem;
}

.grey_box {
  padding: 2rem 1.5rem;
  background-color: #d0d0d738;
  border-radius: 10px;
}

.install-method-container {
  display: flex;
  position: relative;
  margin-top: 1rem;
}

.dropdown-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  // flex: 1;

  .dropdown_column {
    display: flex;
    position: relative;
    flex: 0.9;
  }
}

.page-flag-container {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page-flag {
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  background-color: #353F94;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'UrbanistSemiBold';
  font-size: 1.2rem;
}

.page-flag-next {
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8B94AC;
  font-family: 'UrbanistSemiBold';
  font-size: 1.2rem;
  border: 0.2rem solid #8B94AC;
}

.page-flag-separator {
  height: 0.2rem;
  width: 10rem;
  background-color: #8B94AC;
  margin: 0 0.5rem;
}

.page-flag-separator-next {
  height: 0.2rem;
  width: 10rem;
  background-color: #353F94;
  margin: 0 0.5rem;
}

.form-name-container {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-name {
  color: #5260E2;
  font-size: 1.25rem;
  font-family: 'UrbanistSemiBold';
}

.circular-div {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.75rem;
  background-color: #5260E2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'UrbanistSemiBold';
  font-size: 0.8rem;
  margin-right: 1rem;
}

.dropdown-icon {
  position: absolute;
  top: 0;
  right: 0;
  height: 18px;
  width: 18px;
  border-radius: 9px;
  border: 2px solid grey;
  color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.8rem;
}

.info-symbol {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.75rem;
  border: 0.15rem solid #8B94AC;
  color: #8B94AC;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-left: 1rem;
}



.separator {
  padding-top: 1.5rem;
}

.dropdown-container {
  width: 100%;
}



// relay container
.relay_container {
  background-color: #F8F9FA;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin: 1rem 0;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #8B94AC;
    padding-bottom: 1rem;

    &__title {
      font-family: 'UrbanistSemiBold';
      color: #7389C2;
    }

    button {
      margin: 0 !important;
      background-color: #F8F9FA !important;
      padding: 0 0.2rem;
      width: 5rem;
    }

  }

  &__body {
    padding: 1rem 0;

    .relay_input {
      display: flex;
      align-items: center;
      position: relative;

      &_container {
        display: flex;
        flex-direction: column;

        div {
          width: 3.5rem;
          margin-top: 0;
          margin-right: 1rem;
        }

        p {
          position: absolute;
          font-family: 'UrbanistSemiBold';
          font-size: 12px;
          color: #7389C2;
          bottom: -1.2rem;
        }
      }
    }

  }
}

.access_alert {
  font-family: "UrbanistBold";
  font-size: 10px;
  color: #FF535A;
}

.border {
  width: 100%;
  background-color: #8B94AC;
  height: 1px;
  margin: 1rem 0;
}

.enabledText {
  font-family: 'UrbanistBold';
  font-size: 12px;
  color: #14202A;
}

.row {
  display: flex;
  align-items: center;
  flex: 1;

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
}

.door_sensor {
  background-color: #F8F9FA;
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin: 1rem 0;

  .title{
    font-size: 1rem;
    color: #14202A;
    margin-bottom: 0.875rem;
    font-family: "UrbanistSemiBold";
  }

  .outer_container {
    display: flex;
    gap: 3rem;

    .inner_container {
      display: flex;
      align-items: center;
      font-weight: bolder;
      font-family: 'UrbanistBold';
    }
  }

  .note{
    font-family: 'UrbanistSemiBold';
    font-size: 0.75rem;
    margin-top: 0.75rem;
    color: #353F94;
  }
}